import { FC, type RefAttributes, forwardRef } from 'react'

import { Button as Wrapper } from 'antd'
import { createStyles } from 'antd-style'

import { useColor } from '../app'
import IvyIcon from '../icon'
import Text from '../text/Text'

const useStyles = createStyles(({ token, css }: { token: any; css: any }) => ({
  primary: css`
    background: ${token.btnPrimaryBg}!important;
    font-family: ${token.btnFontFamily}!important;
    border-color: ${token.btnPrimaryBorderColor} !important;
    color: ${token.btnPrimaryColor} !important;
    text-transform: ${token.btnTextTransform} !important;
    font-weight: ${token.btnFontWeight} !important;
    &:hover {
      background: ${token.btnPrimaryHoverBg} !important;
      border-color: ${token.btnPrimaryHoverBorderColor} !important;
      color: ${token.btnPrimaryHoverColor} !important;
    }
  `,
  danger: css`
    background: ${token.colorError} !important;
    border-color: ${token.colorErrorBorder} !important;
    font-family: ${token.btnFontFamily} !important;
    font-weight: ${token.btnFontWeight} !important;
    color: white !important;
    text-transform: ${token.btnTextTransform};
    &:hover {
      background: ${token.colorError} !important;
      border-color: ${token.colorErrorBorder} !important;
    }
  `,
  default: css`
    background: ${token.btnDefaultBg} !important;
    border-color: ${token.btnDefaultBorderColor} !important;
    font-weight: ${token.btnFontWeight} !important;
    font-family: ${token.btnFontFamily} !important;
    color: ${token.btnDefaultColor} !important;
    text-transform: ${token.btnTextTransform};
    &:hover {
      background: ${token.btnDefaultHoverBg} !important;
      color: ${token.btnDefaultHoverColor} !important;
      border-color: ${token.btnDefaultHoverBorderColor} !important;
    }
  `,
  ghost: css`
    background: ${token.btnGhostBg} !important;
    border-color: ${token.btnGhostBorderColor} !important;
    font-family: ${token.btnFontFamily} !important;
    font-weight: ${token.btnFontWeight} !important;
    text-transform: ${token.btnTextTransform};
    color: ${token.btnGhostColor} !important;
    &:hover {
      background: ${token.btnGhostBg} !important;
      color: ${token.btnGhostHoverColor} !important;
      border-color: ${token.btnGhostHoverBorderColor} !important;
    }
  `,
  text: css`
    background: transparent;
    border-color: ${token.btnDefaultBorderColor};
    font-family: ${token.btnFontFamily};
    font-weight: ${token.btnFontWeight};
    color: ${token.textColor};
    text-transform: ${token.btnTextTransform};
    &:hover {
      background: ${token.btnDefaultHoverBg};
      color: ${token.btnGhostHoverColor};
    }
  `,
  link: css`
    font-family: ${token.fontFamily};
    color: ${token.linkColor};
    border: none !important;
    &:hover {
      background: initial !important;
      color: ${token.linkHoverColor};
    }
  `,
}))

const LoadingIcon = () => (
  <svg viewBox='0 0 1024 1024' focusable='false'>
    <path d='M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z' />
  </svg>
)

type ButtonProps = SDK.Components.ButtonProps &
  RefAttributes<HTMLButtonElement> & {
    invoiceButton?: boolean
  }

const Button: FC<ButtonProps> = forwardRef(
  (
    {
      type = 'primary',
      htmlType,
      title = '',
      goTo,
      children,
      theme,
      block,
      wide = block,
      outlined = false,
      shape,
      icon,
      ghost = false,
      href,
      context,
      disabled = false,
      loading = false,
      translate = 'yes',
      style,
      textProps,
      uppercased = true,
      danger = false,
      ...props
    },
    ref,
  ) => {
    const { styles } = useStyles()
    const { token } = useColor()

    return (
      <Wrapper
        data-cy={'Button'}
        data-title={children}
        ref={ref}
        className={ghost ? styles['ghost'] : danger ? styles.danger : (styles[type] ?? '')}
        style={{
          width:
            block && shape === 'default'
              ? '100%'
              : type === 'link' && !children
                ? 'fit-content'
                : icon && !children
                  ? token.controlHeight
                  : undefined,
          height: type === 'link' && !children ? 'fit-content' : token.controlHeight,
          textAlign: style?.textAlign ?? 'center',
          ...style,
        }}
        htmlType={htmlType}
        type={type}
        disabled={disabled}
        href={href}
        block={block || !icon || !children || !href}
        loading={loading}
        title={title}
        shape={shape}
        icon={
          loading ? (
            <LoadingIcon />
          ) : typeof icon === 'string' ? (
            <IvyIcon size={16} type={icon as Design.IvyIcon} />
          ) : (
            icon
          )
        }
        {...props}
      >
        {children ||
          (title && (
            <Text
              font={{ family: 'secondary' }}
              strong
              color={'inherit'}
              uppercased
              context={context}
              translate={translate}
              {...textProps}
            >
              {children || title}
            </Text>
          ))}
      </Wrapper>
    )
  },
)

export { Button }
