import { lazy } from 'react'

import Result from '@peoplevine/sdk/components/result'
import routes from '@peoplevine/sdk/routes'

import AccountRoutes from 'src/components/account/routes'

type RoutePath = keyof typeof routes

const globalNetworkRoutes: Partial<{ [key in keyof typeof routes]: SDK.Components.Route }> = {
  globalNetworkSwitch: {
    id: 'globalNetworkSwitchGuest',
    secure: false,
    Component: lazy(() => import('src/components/global-network/GlobalNetworkRoute')),
  },
}

const guestRoutes: Partial<{ [key in keyof typeof routes]: SDK.Components.Route }> = {
  verifyEmail: { secure: false, Component: lazy(() => import('src/components/user/verifyEmail/VerifyEmail')) },
  calendarRedirect: { secure: false, Component: lazy(() => import('src/components/redirect/CalendarRedirect')) },
  forgotPassword: { secure: false, Component: lazy(() => import('src/components/user/password/reset/ForgotPassword')) },
  forgotPasswordReset: {
    secure: false,
    Component: lazy(() => import('src/components/user/password/reset/PasswordReset')),
  },
  guestPassShare: { secure: false, Component: lazy(() => import('src/components/public/guest-pass/GuestPass')) },
  guestTicket: { secure: false, Component: lazy(() => import('src/components/public/guest-ticket/GuestTicket')) },
  passbookDownload: { secure: false, Component: lazy(() => import('src/components/passbook/PassDownload')) },
  register: { secure: false, Component: lazy(() => import('src/components/user/CreateAccount')) },
  actions: { secure: false, Component: lazy(() => import('src/components/actions/Actions')) },
  // unsubscribeEmail: { secure: false, Component: lazy(() => import('src/components/actions/EmailUnsubscribe')) },
  activate: { secure: false, Component: lazy(() => import('src/components/user/activate/verify/VerifyEmail')) },
  userLogin: { secure: false, Component: lazy(() => import('src/components/user/Login')) },
  membershipPaywall: {
    secure: true,
    Component: lazy(() => import('src/components/paywall')),
    wildcard: true,
  },
}

const sharedRoutes = {
  faq: {
    Component: lazy(() => import('src/components/faq/Faq')),
  },
  privacy: {
    Component: lazy(() => import('src/components/privacy/Privacy')),
  },
  form: {
    Component: lazy(() => import('src/components/surveys/Survey')),
    aliases: ['/survey/:id', '/surveys/:id'],
  },
  static: { Component: lazy(() => import('src/components/index/Static')) },
}

const drawerRoutes: Partial<{ [key in keyof typeof routes]: SDK.Components.Route }> = {
  accountDue: {
    Component: lazy(() => import('src/components/account/tabs/statement/membership/MembershipFeesStatement')),
  },
  // accountMembershipSubmember: {
  //   Component: lazy(() => import('src/components/account/tabs/membership/form/AddSubMember')),
  // },

  accountOrder: {
    Component: lazy(() => import('src/components/account/tabs/order/Order')),
  },
  accountStatement: {
    Component: lazy(() => import('src/components/account/tabs/statement/Statement')),
  },
  accountTransaction: {
    Component: lazy(() => import('src/components/transactions/TransactionDetails')),
  },
  accountWalletBank: {
    Component: lazy(() => import('src/components/account/tabs/wallet/bank-account/BankAccountView')),
  },
  // accountWalletCreate: {
  //   Component: lazy(() => import('src/components/account/tabs/wallet/payment-methods/PaymentMethodAdd')),
  // },
  accountWalletCreditCard: {
    Component: lazy(() => import('src/components/account/tabs/wallet/credit-card/CreditCardEdit')),
  },
  accountWalletTicket: {
    membersOnly: false,
    Component: lazy(() => import('src/components/my-schedule/bookings/EventDetails')),
  },
  blogDetails: {
    Component: lazy(() => import('src/components/blog/BlogDrawer')),
  },
  myScheduleEvent: {
    Component: lazy(() => import('src/components/my-schedule/bookings/EventDetails')),
  },
  clubBenefits: {
    Component: lazy(() => import('src/components/perks/Details')),
  },
  myScheduleAppointments: {
    Component: lazy(() => import('src/components/my-schedule/bookings/AppointmentDetails')),
  },
  myScheduleReservations: {
    Component: lazy(() => import('src/components/my-schedule/bookings/ReservationDetails')),
  },
  voucher: {
    Component: lazy(() => import('src/components/perks/VoucherDetails')),
  },
}

const memberRoutes: Partial<{ [key in keyof typeof routes]: SDK.Components.Route }> = {
  account: AccountRoutes,
  activity: {
    Component: lazy(() => import('src/components/activities/Activity')),
    membersOnly: true,
  },
  accountMembershipsNomination: {
    Component: lazy(() => import('src/components/account/tabs/membership/nomination/MemberNominationDrawer')),
  },
  // form: {
  //   Component: lazy(() => import('src/components/surveys/Survey')),
  // },
  // survey: {
  //   Component: lazy(() => import('src/components/surveys/SurveyView')),
  // },
  activities: {
    Component: lazy(() => import('src/components/activities/Activities')),
    membersOnly: true,
  },
  appointment: {
    Component: lazy(() => import('src/components/appointment/Appointment')),
    membersOnly: true,
  },
  appointments: {
    Component: lazy(() => import('src/components/appointment/Appointments')),
    membersOnly: true,
  },
  blogs: {
    Component: lazy(() => import('src/components/blog/Blogs')),
    membersOnly: true,
    aliases: ['/blog'],
  },
  checkout: {
    Component: lazy(() => import('src/components/checkout/Checkout')),
    membersOnly: false,
  },
  clubBenefitsTab: {
    Component: lazy(() => import('src/components/perks/Perks')),
    membersOnly: false,
  },
  vouchersTab: {
    Component: lazy(() => import('src/components/perks/Perks')),
    membersOnly: false,
  },
  dining: {
    Component: lazy(() => import('src/components/dining/restaurants/Restaurant')),
    aliases: ['/dining/:id'],
    membersOnly: true,
  },
  dinings: {
    Component: lazy(() => import('src/components/dining/restaurants/Restaurants')),
    membersOnly: true,
  },
  directory: {
    Component: lazy(() => import('src/components/directory/Directories')),
    membersOnly: true,
  },
  directoryPage: {
    Component: lazy(() => import('src/components/directory/Businesses')),
    membersOnly: true,
  },
  directoryCompany: {
    Component: lazy(() => import('src/components/directory/Businesses')),
  },
  event: {
    Component: lazy(() => import('src/components/event/Event')),
    membersOnly: false,
    aliases: ['/event/:id'],
  },
  events: {
    Component: lazy(() => import('src/components/event/Events')),
    membersOnly: false,
  },

  guestList: {
    Component: lazy(() => import('src/components/guest-list/GuestList')),
    membersOnly: false,
  },
  index: {
    Component: lazy(() => import('src/components/index/MyClub')),
    secure: false,
    membersOnly: false,
  },
  message: {
    Component: lazy(() => import('src/components/messaging/Messaging')),
    membersOnly: false,
    secure: true,
  },
  myClub: {
    Component: lazy(() => import('src/components/index/MyClub')),
  },
  mySchedule: {
    Component: lazy(() => import('src/components/my-schedule/MySchedule')),
  },
  notifications: {
    Component: lazy(() => import('src/components/notifications/Notifications')),
  },
  pageId: {
    Component: lazy(() => import('src/components/pages/Page')),
    membersOnly: false,
  },
  room: {
    Component: lazy(() => import('src/components/rooms/Room')),
    membersOnly: false,
  },
  rooms: {
    Component: lazy(() => import('src/components/rooms/Rooms')),
    membersOnly: false,
  },
  spa: {
    Component: lazy(() => import('src/components/spa/Spa')),
    membersOnly: true,
  },
  spas: {
    Component: lazy(() => import('src/components/spa/Spas')),
    membersOnly: true,
  },
  userLogout: {
    Component: lazy(() => import('src/components/user/Logout')),
    membersOnly: false,
  },
  _wildcard: {
    Component: Result.NotFound,
  },
  terms: {
    Component: lazy(() => import('src/components/index/Terms')),
  },
}

function hasRoute(path: keyof typeof routes) {
  return memberRoutes[path] !== undefined
}

export { drawerRoutes, globalNetworkRoutes, guestRoutes, hasRoute, memberRoutes, sharedRoutes, type RoutePath }
